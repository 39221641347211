import parameters from '../parameters';
import { auth } from "../firebase";
import { signin } from './auth';
import store from 'store/store';
import { updateUser } from 'store/user/actions';

export const enterQueue = async() => {
    const result = await parameters.post("/enterQueue", {
        uid: auth.currentUser?.uid,
        airportCode: store.getState().userReducer.user.airportCode,
        parkingSpots: store.getState().airportReducer.airport.parkingSpots
    })
    const getUserInfo = await signin();
    store.dispatch(updateUser(getUserInfo.data.data[0]))
    return result;
}

export const leaveParkingSpot = async(uid) => {
    const result = await parameters.post("/leaveParkingSpot", {
        uid: uid,
        airportCode: store.getState().userReducer.user.airportCode,
        parkingSpots: store.getState().airportReducer.airport.parkingSpots
    })
    if(uid === auth.currentUser?.uid){
        const getUserInfo = await signin();
        store.dispatch(updateUser(getUserInfo.data.data[0]))
    }
    return result
}

export const leaveQueue = async() => {
    const result = await parameters.post("/leaveQueue", {
        uid: auth.currentUser?.uid
    })
    const getUserInfo = await signin();
    store.dispatch(updateUser(getUserInfo.data.data[0]))
    return result
}

export const getTaxisInParkingSpots = async() => {
    return await parameters.get("/getTaxisInParkingSpots", { params: {
        airportCode: store.getState().userReducer.user.airportCode
    }});
}

export const countParkingEvents = async(timestamp, timeFrame) => {
    return await parameters.post("/countParkingEvents", {
        timestamp: timestamp,
        timeFrame: timeFrame,
        airportCode: store.getState().userReducer.user.airportCode
    })
}

export const getTaxisInQueue = async() => {
    return await parameters.get("/getTaxisInQueue", { params: {
        airportCode: store.getState().userReducer.user.airportCode
    }});
}

export const savePosition = async(lat, lng) => {
    return await parameters.post("/savePosition", {
        uid: auth.currentUser?.uid,
        lat: lat,
        lng: lng
    })
}

export const getTaxiLocations = async() => {
    return await parameters.get("/getTaxiLocations", { params: {
        airportCode: store.getState().userReducer.user.airportCode
    }});
}

export const saveParkingQueueEvent = async(eventFields) => {
    return await parameters.post("/saveParkingQueueEvent", eventFields)
}

export const getParkingQueueEvents = async(endTimestamp) => {
    return await parameters.get("/getParkingQueueEvents", { params: {
        timestamp: store.getState().filterFieldsBarReducer.flightDate.getTime()/1000,
        airportCode: store.getState().userReducer.user.airportCode,
        endTimestamp
    }});
}

export const textTaxiDriver = async(phoneNumber, firstAndLastName) => {
    return await parameters.post("/textTaxiDriver", {
        phoneNumber: phoneNumber,
        firstAndLastName: firstAndLastName
    })
}

export const getTaxiDrivers = async() => {
    return await parameters.get("/getTaxiDrivers", { params: {
        airportCode: store.getState().userReducer.user.airportCode
    }});
}

export const enterParkingSpotZone = async() => {
    await parameters.post("/enterParkingSpotZone", {
        uid: auth.currentUser?.uid
    })
}

export const leaveParkingSpotZone = async() => {
    await parameters.post("/leaveParkingSpotZone", {
        uid: auth.currentUser?.uid
    })
}