import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { countParkingEvents } from "api/endpoints/parkingQueue";
import SelectDates from "components/SelectDates/SelectDates";
import TimeFrameSelection from 'components/TimeFrameSelection/TimeFrameSelection';
import WordCountBarGraph from 'components/WordCountBarGraph/WordCountBarGraph';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import TimeFrameContainer from 'components/TimeFrameContainer/TimeFrameContainer';

const useStyles = makeStyles({
    container:{
        padding: 15,
        height: 600
    },
    gridContainer:{
        marginBottom: 20
    },
    gridItems:{
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center"
    },
    airport:{
        fontSize: 13
    }
});

const ParkingRerpotsEventsBarGraph = (props) => {
    const { flightDate } = props;
    const classes = useStyles();
    const [timeFrame, setTimeFrame] = useState("Last 24 hours")
    const [eventCounts, setEventCounts] = useState([])

    useEffect(() => {
        let active = true;
        const loadEvents = async() => {
            const count = await countParkingEvents(flightDate.getTime()/1000, timeFrame);
            if (!active) { return }
            setEventCounts(count.data.data)
        }
        loadEvents()
        return () => { active = false }
    }, [timeFrame])

    return (
        <Paper className={classes.container}>
            <TimeFrameContainer title = "Parking Events Graph" timeFrame = {timeFrame} setTimeFrame = {setTimeFrame}/>
            <Box sx={{height: { xs: 450, sm: 500}}}>
                <WordCountBarGraph data = {eventCounts}/>
            </Box>
        </Paper>
    );
}

const mapStateToProps = (state) => {
    return{
        flightDate: state.filterFieldsBarReducer.flightDate,
    }
}
  
export default connect(mapStateToProps)(ParkingRerpotsEventsBarGraph)