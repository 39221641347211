import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SelectDates from 'components/SelectDates/SelectDates';
import TimeFrameSelection from 'components/TimeFrameSelection/TimeFrameSelection';
import React from 'react';
import { convertToLocal } from 'utils/timeConversions';

const useStyles = makeStyles(theme => ({
  timeFrameContainer:{
    display: "flex", 
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      textAlign: "center"
    }
  },
  selectionContainer:{
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10
    }
  }
}));

const TimeFrameContainer = ({ title, timeFrame, setTimeFrame, downloadReportsEnabled, downloadData, setTimeFrameValue }) => {
  const classes = useStyles();

  function convertToCSV() {
    const headers = title === "Payment Logs" ? [
      ...Object.keys(downloadData[0]),
      "Stripe Fee (2.9% + $0.30)",
      "UnDelay fee (3%)",
    ] ? title === "Parking Events Table" : [
      ...Object.keys(downloadData[0]),
      "Reduced",
    ] : [...Object.keys(downloadData[0])];

    const dataLogs = [headers].concat(downloadData);
  
    const csvData = dataLogs.map(originalLog => {
      let log = { ...originalLog };
    
      if (log.timestamp) {
        if (title === "Payment Logs") {
          log["Stripe Fee (2.9% + $0.30)"] = (originalLog.paymentAmount * 0.029 + 0.30).toFixed(2);
          log["UnDelay fee (3%)"] = (originalLog.paymentAmount * 0.03).toFixed(2);
        } else if (title === "Parking Events Table") {
          log["Reduced"] = originalLog.event === "Entered Parking Zone" ? 3 : 0;
        }
    
        log.timestamp = convertToLocal(originalLog.timestamp).replace(",", "");
      }
    
      return Object.values(log).toString();
    }).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = `${timeFrame}_payment_logs.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const handleReportDownload = () => {
    convertToCSV()
  }

  return (
    <Grid container className={classes.timeFrameContainer}> 
      <Grid item xs = {12} sm = {6}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid container className={classes.selectionContainer}>
        <Grid item xs = {12} sm = {6} 
          sx={{
            justifyContent: { xs: 'center', sm: 'flex-end'},
            display: "flex",
            marginBottom: {xs: 3, sm: 0},
            marginTop: {xs: 3, sm: 0}
          }}
        >
          <SelectDates/>
        </Grid>
        <Grid item  xs = {12} sm = {6} 
          sx={{
            justifyContent: { xs: 'center', sm: 'flex-end'},
            display: "flex"
          }}
        >
          <TimeFrameSelection 
            timeFrame = {timeFrame}
            setTimeFrame = {setTimeFrame}
            setTimeFrameValue = {setTimeFrameValue}
          />
          {downloadReportsEnabled && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleReportDownload}
              style={{marginRight: 10, marginLeft: 10}}
            >
              Download Report
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TimeFrameContainer;
