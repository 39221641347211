import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonGroup from '@mui/material/ButtonGroup';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from "react";
import { updatePaymentIntent } from "api/endpoints/payments";
import { updateUser } from 'store/user/actions';
import { saveParkingQueueEvent } from 'api/endpoints/parkingQueue';
import { connect } from 'react-redux';
import { auth } from "api/firebase";
import { updateAccountBalance, savePaymentLog } from 'api/endpoints/payments'
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

const useStyles = makeStyles({
    loadingContainer:{
      justifyContent: "center",
      display: "flex",
      padding: 10,
      alignSelf: "center",
      margin: "0 auto"
    },
    errorMessage:{
        paddingTop: 10,
        textAlign: "center"
    }
});

const PaymentModal = (props) => {
    const classes = useStyles();
    const { 
        user,
        paymentModalOpen, 
        setPaymentModalOpen, 
        paymentIntentId, 
        enterQueue, 
        setIsQueueStatusLoading,
        updateUser
    } = props;
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(20);

    const handleClose = () => {
        if(setIsQueueStatusLoading){
            setIsQueueStatusLoading(false);
        }
        setPaymentModalOpen(false);
        setMessage(null)
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!stripe || !elements) {
          return;
        }
        
        if(paymentAmount < 20){
            setMessage("Please enter a payment value > $20");
            return;
        }
    
        setIsLoading(true);

        try{
            await updatePaymentIntent(paymentIntentId, paymentAmount)
            const payment = await stripe.confirmPayment({
                elements,
                redirect: "if_required",
                confirmParams: {
                  return_url: window.location.href,
                },
            });
            if(payment?.paymentIntent?.status){
                switch (payment.paymentIntent.status) {
                    case "succeeded":
                        setMessage("Payment succeeded!");
                        if(enterQueue){
                            setIsQueueStatusLoading(false);
                            await Promise.all([
                                enterQueue(),
                                saveParkingQueueEvent({
                                    uid: auth.currentUser?.uid,
                                    airportCode: user.airportCode,
                                    firstAndLastName: user.firstAndLastName,
                                    companyName: user.companyName,
                                    taxiNumber: user.taxiNumber,
                                    event: "Entered Queue"
                                })
                            ]);
                        }
                        await Promise.all([
                            updateAccountBalance("+", paymentAmount),
                            savePaymentLog(user.airportCode, paymentAmount, "added")
                        ]);
                        updateUser({ ...user, accountBalance: Number(user.accountBalance) + Number(paymentAmount)})
                      break;
                    case "processing":
                        setMessage("Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        setMessage("Your payment was not successful, please try again.");
                        break;
                    default:
                        setMessage("Something went wrong.");
                        break;
                }
            }else if (payment?.error){
                if (payment.error.type === "card_error" || payment.error.type === "validation_error") {
                    setMessage(payment.error.message);
                } else {
                    setMessage("An unexpected error occurred.");
                }
            }
        }catch(e){
            console.log(e)
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    return (
        <Dialog
            disableEnforceFocus
            open={paymentModalOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {message !== "Payment succeeded!" && (
                <>
                    <DialogTitle id="alert-dialog-title">
                        Payment Information
                    </DialogTitle>

                    <ButtonGroup 
                        variant="outlined" 
                        aria-label="outlined button group"
                        style = {{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Button onClick={()=> setPaymentAmount(20)}>$20</Button>
                        <Button onClick={()=> setPaymentAmount(40)}>$40</Button>
                        <Button onClick={()=> setPaymentAmount(60)}>$60</Button>
                        <Button onClick={()=> setPaymentAmount(80)}>$80</Button>
                    </ButtonGroup>

                    <div style = {{paddingLeft: 20, paddingRight: 20, paddingTop: 20}}>
                        <CurrencyTextField
                            fullWidth
                            variant="outlined"
                            value={paymentAmount}
                            currencySymbol="$"
                            maximumValue={"10000"}
                            outputFormat="string"
                            decimalCharacter="."
                            digitGroupSeparator=","
                            onChange={(event, value)=> setPaymentAmount(value)}
                            helperText = "$20 min, $10k max"
                        />
                    </div>
                </>
            )}

            <DialogContent>
                <form id="payment-form" onSubmit={handleSubmit}>
                    {message !== "Payment succeeded!" && <PaymentElement id="payment-element" />}
                    {message && <div id="payment-message" className={classes.errorMessage}>{message}</div>}
                </form>
            </DialogContent>
            <DialogActions>
                {isLoading ? 
                    <div style = {{padding: 10}}>
                        <CircularProgress/>
                    </div>
                : message !== "Payment succeeded!" && (!message || !isLoading || stripe || elements) ? 
                    <>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button form='payment-form' type="submit">
                            Pay Now
                        </Button>
                    </>
                : 
                    <Button onClick={handleClose}>Done</Button>
                }
            </DialogActions>
        </Dialog>
    );
}

const mapDispatchToProps = {
    updateUser
}

const mapStateToProps = (state) =>{
    return{
        user: state.userReducer.user,
        airport: state.airportReducer.airport
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal)